// DataTable.js

import React from 'react';

function DataTable({ data, vehicleColors }) {
  return (
    <div style={{ padding: '5px 5%' }}>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr style={{ backgroundColor: '#aaaaaa' }}>
            <th style={{ border: '1px solid black', padding: '5px' }}></th>
            <th style={{ border: '1px solid black', padding: '5px' }}>VehicleID</th>
            <th style={{ border: '1px solid black', padding: '5px' }}>Mode</th>
            <th style={{ border: '1px solid black', padding: '5px' }}>Speed</th>
            <th style={{ border: '1px solid black', padding: '5px' }}>SoC</th>
            <th style={{ border: '1px solid black', padding: '5px' }}>SoftwareVersion</th>
            <th style={{ border: '1px solid black', padding: '5px' }}>Location</th>
            <th style={{ border: '1px solid black', padding: '5px' }}>LastTimeUpdated</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.number} style={{backgroundColor: vehicleColors[row.id] || 'white', color: 'black'}}>
              <td style={{ border: '1px solid black', padding: '5px' }}>{row.number}</td>
              <td style={{ border: '1px solid black', padding: '5px' }}>{row.id}</td>
              <td style={{ border: '1px solid black', padding: '5px' }}>{row.mode}</td>
              <td style={{ border: '1px solid black', padding: '5px' }}>{row.speed}</td>
              <td style={{ border: '1px solid black', padding: '5px' }}>{row.soc}</td>
              <td style={{ border: '1px solid black', padding: '5px' }}>{row.softwareVersion}</td>
              <td style={{ border: '1px solid black', padding: '5px' }}>
                {(row.latitude && row.longitude) ? `${row.latitude}, ${row.longitude}` : 'N/A'}
              </td>
              <td style={{ border: '1px solid black', padding: '5px' }}>{row.LastTimeUpdated}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default DataTable;

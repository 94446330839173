import React, { useRef, useEffect } from 'react';
import { GoogleMap, LoadScript, OverlayView } from '@react-google-maps/api';
import randomcolor from 'randomcolor';

const containerStyle = {
  width: '100%',
  height: '450px'
};

const defaultCenter = {
  lat: 1.3521,
  lng: 103.8198
};

function GoogleMapComponent({ gpsData, setVehicleColors }) {
  const mapRef = useRef(null);
  const vehicleColors = useRef({});

  useEffect(() => {
    if (gpsData.length > 0 && mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();
      gpsData.forEach(item => {
        const { latitude, longitude } = item;
        if(latitude && longitude) {
          bounds.extend({ lat: parseFloat(latitude), lng: parseFloat(longitude) });
        }
      });
      mapRef.current.fitBounds(bounds);
      mapRef.current.setZoom(mapRef.current.getZoom() - 2); // reduce zoom level by 2
    }
  }, [gpsData]);

  const getVehicleColor = (id) => {
    if (!vehicleColors.current[id]) {
      vehicleColors.current[id] = randomcolor({ luminosity: 'bright' });
    }
    return vehicleColors.current[id];
  }

  useEffect(() => {
    setVehicleColors(vehicleColors.current);
  }, [gpsData, setVehicleColors]);

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={defaultCenter}
        zoom={11}
        onLoad={(map) => { mapRef.current = map; }}
      >
        {gpsData.map((item, index) => {
          const { latitude, longitude, id } = item;
          return (
            latitude && longitude &&
            <OverlayView
              key={index}
              position={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <div style={{
                height: '15px',  // updated from 5px to 15px
                width: '15px',  // updated from 5px to 15px
                backgroundColor: getVehicleColor(id),
                borderRadius: '50%',
              }}/>
            </OverlayView>
          )
        })}
      </GoogleMap>
    </LoadScript>
  );
}

export default React.memo(GoogleMapComponent);

// Login.js

import React, { useState } from 'react';

const usersData = [
  { username: 'user1', password: 'password1' },
  { username: 'user2', password: 'password2' },
  { username: '1', password: '1' }
];

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();

    const user = usersData.find((user) => user.username === username);
    if (!user) {
      setError("Username doesn't exist");
      return;
    }

    if (user.password !== password) {
      setError('Password is incorrect');
      return;
    }

    onLogin();
  };

  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh'
    }}>
      <div style={{ padding: '30px', backgroundColor: '#aaaaaa', width: '25%' }}>
        <h2>AV LIVE TRACKING LOGIN PAGE</h2>
        <form onSubmit={handleLogin} style={{ display: 'flex', flexDirection: 'column' }}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            style={{ marginBottom: '15px' }}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ marginBottom: '15px' }}
          />
          <button style={{ backgroundColor: 'green', color: 'white', alignSelf: 'flex-end', padding: '10px'}}>Login</button>
        </form>
        {error && <p style={{ color: 'red', fontWeight: 'bold' }}>{error}</p>}
      </div>
    </div>
  );
}

export default Login;

import React, { useEffect, useState } from 'react';
import GoogleMapComponent from './components/GoogleMapComponent';
import DataTable from './components/DataTable';
import NotificationBanner from './components/NotificationBanner';
import Login from './components/Login';


const sampleData = [
  { number: '1', id: 'Bus 1', mode: 'MANUAL', speed: 0, soc: 0, softwareVersion: 'alphard-2.1.1-4-g8e25f55', latitude: 1.3330929344287201, longitude: 103.77170229301704, LastTimeUpdated: 'May 24, 2023, 5:00:08 PM'},
  { number: '2', id: 'Bus 2', mode: 'MANUAL', speed: 14.300127, soc: 73, softwareVersion: 'MVP-2.3.2', latitude: 1.3332444663784431, longitude: 103.77203417847976, LastTimeUpdated: 'May 24, 2023, 2:22:29 PM'},
];


function App() {
  const [gpsData, setGpsData] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [vehicleColors, setVehicleColors] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    // Simulate fetching data from the database
    await new Promise((resolve) => setTimeout(resolve, 1000));

    setGpsData(sampleData);
  }

  if (!isLoggedIn) {
    return <Login onLogin={() => setIsLoggedIn(true)} />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: '100vh' }}>
      <div style={{ flexBasis: '85%' }}>
      <h1 style={{ padding: '10px' }}>AV LIVE TRACKING PAGE</h1>
        <GoogleMapComponent gpsData={gpsData} setVehicleColors={setVehicleColors} style={{ height: '80vh' }} />
        <DataTable data={gpsData} vehicleColors={vehicleColors} />
      </div>
      <div style={{ flexBasis: '15%' }}>
        <NotificationBanner onLogout={() => setIsLoggedIn(false)} />
      </div>
    </div>
  );
}

export default App;

// NotificationBanner.js

import React, { useState, useEffect, useRef } from 'react';

function NotificationBanner({ onLogout }) {
  const [notifications, setNotifications] = useState([]);
  const [blinking, setBlinking] = useState(true);
  const [started, setStarted] = useState(false);

  // Prepare the beep sound
  const beep = useRef(new Audio(process.env.PUBLIC_URL + '/beep.mp3'));

  // Start notifications once the button is clicked
  const startNotifications = () => {
    // Get the current date and time
    const now = new Date();
    const newNotification = `${now} - New DBW Event`;

    // Add the new notification to the start of the array and remove the last one if there are more than 10
    setNotifications((prev) => [newNotification, ...prev.slice(0, 4)]);
    
    // Make the new notification blink for 5 seconds
    setBlinking(true);
    setTimeout(() => setBlinking(false), 5000);

    // Play the beep sound
    beep.current.play();
  }

  useEffect(() => {
    let timer;
    if (started) {
      timer = setInterval(startNotifications, 6000); // new notification every 60 seconds
    }
    return () => clearInterval(timer); // cleanup on component unmount
  }, [started]);

  return (
    <div style={{ 
      height: '94.8vh', 
      backgroundColor: '#777777',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between', // Changed to 'space-between' to distribute space between items
      alignItems: 'center', // this centers the content horizontally
      padding: '20px'
    }}>
      <h3 style={{ color: 'white', margin: 0 }}>Failure Notification</h3>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', // Center the content vertically
        alignItems: 'center', // Center the content horizontally
        flex: 1, // This makes the container take up all available space between the header and the logout button
      }}>
        {!started && (
          <button 
            onClick={() => setStarted(true)} 
            style={{ 
              backgroundColor: 'green', 
              color: 'white', 
              fontSize: '2em',
              marginBottom: '20px' 
            }}
          >
            Click start to show notifications
          </button>
        )}
        {started && (
          <div style={{
              marginTop: '10px',
              marginBottom: '20px',
              overflowY: 'auto',
              maxHeight: '83vh', // Set a maximum height to prevent overflow
          }}>
            {notifications.map((notification, index) => (
              <div 
                key={index} 
                style={{
                  backgroundColor: index === 0 && blinking ? 'red' : '#ffffff', 
                  color: '#111111', 
                  margin: '10px 0',
                  padding: '10px',
                  borderRadius: '5px'
                }}
              >
                {notification}
              </div>
            ))}
          </div>
        )}
      </div>
      <button onClick={onLogout} style={{ backgroundColor: 'green', color: 'white', paddingLeft:'20px', paddingRight:'20px', paddingTop:'10px', paddingBottom:'10px' }}>Logout</button>
    </div>
  );
}

export default NotificationBanner;
